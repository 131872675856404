import { SelectableValue } from "@grafana/data";

export function capitalizeFirstLetter(string: String) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function generateOptions(optionsArray: Array<String | SelectableValue>) {
  // takes array of lowercase keys and formats them to be select options
  // pass an array of options and they'll be formatted for grafana-ui's select component. option examples:
  //  <string>: returns {value: <string>, label: <String (first letter capitalized)>}
  //  {value, label}: returns the input object unchanged
  //  {value, description}: returns an object with value and description unchanged, but adds a label
  return optionsArray.map(option => {
    const optionObj: SelectableValue = typeof option === 'string' ? {value: option} : option // convert string options into an obj
    return {
      ...optionObj, // value, description, and imgUrl will now be sent through unchanged.
      label: optionObj?.label || capitalizeFirstLetter(optionObj.value) // compute the label if one wasn't passes
    }
  })
}

/**
 * Download contents as a file
 * Source: https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
 */
export function downloadBlob(content: string, filename: string, contentType: string) {
  // Create a blob
  const blob = new Blob([content], { type: contentType });
  const url = URL.createObjectURL(blob);

  // Create a link to download it
  const pom = document.createElement('a');
  pom.href = url;
  pom.setAttribute('download', filename);
  pom.click();
}
