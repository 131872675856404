import React, { ChangeEvent, useState } from 'react';
import { AppEvents, GrafanaTheme2, PanelProps, SelectableValue } from '@grafana/data';
import { Options } from '../module';
import { getAppEvents, getBackendSrv } from '@grafana/runtime';
import { AsyncSelect, Button, IconButton, InlineField, Input, Modal, Select, Stack, useStyles2 } from '@grafana/ui';
import { AlertReceivers, RuleGroupResponse } from 'simplified-alerting-panel/types';
import { css } from '@emotion/css';
import { generateOptions } from 'shared/utils';

const backendSrv = getBackendSrv();
const appEvents = getAppEvents();

export function MainPanel({ data, replaceVariables }: PanelProps<Options>) {
  const s = useStyles2(getStyles);

  // alert creation modal and params
  const [modalOpen, setModalOpen] = useState(false);
  interface AlertParams {
    title: string;
    lookbackSeconds: number;
    spikeAlertThreshold: number;
    averageAlertThreshold: number;
    pendingPeriod: string;
    receiver: SelectableValue<string | null>;
  }
  const alertDefaultParams = {
    title: replaceVariables("${emissionsMetric:text} alert " + `(${new Date().toLocaleString()})`),
    lookbackSeconds: 900,
    spikeAlertThreshold: 7000,
    averageAlertThreshold: 5000,
    pendingPeriod: '15m',
    receiver: {value: null}
  };
  const [alertParams, setAlertParams] = useState<AlertParams>(alertDefaultParams);

  const fetchContactPoints = () => {
    return new Promise<Array<SelectableValue<string>>>(async (resolve) => {
      const receivers: AlertReceivers[] = await backendSrv.get('/api/v1/notifications/receivers')
      resolve(generateOptions(receivers.map(receiver => receiver.name)))
    });
  };

  const createAlert = async () => {
    // this is whatever the user sets to be the data source of the panel. used to get the dashboard/folder IDs and active datasource
    const dashboardUid = data?.request?.dashboardUID || '';
    const folderUid = dashboardUid.replace('-db', '');
    const datasourceUid = data?.request?.targets?.[0]?.datasource?.uid || '';

    // get alert rules that already exist for this folder every time a new alert is created, otherwise you'll overwrite some.
    const folderDashboardAlertGroups: RuleGroupResponse = await backendSrv.get(
      `/api/ruler/grafana/api/v1/rules/${folderUid}?subtype=cortex`
    );
    const ourAlertGroup = Object.values(folderDashboardAlertGroups).flat().find((group) => group.name === 'alerts');
    const alertRules = ourAlertGroup?.rules || [];

    // this is the generic query to find data from the selected time period, within the site's bbox
    const query = replaceVariables(`
      SELECT
        time,
        measure_value::double as value
      FROM
        $__database.sensors
      WHERE
        $__timeFilter
        AND
        -- siteBBox array is [min_lon, min_lat, max_lon, max_lat]
        cast(longitude as double) between element_at(ARRAY$siteBBox, 1) and element_at(ARRAY$siteBBox, 3)
        AND
        cast(latitude as double) between element_at(ARRAY$siteBBox, 2) and element_at(ARRAY$siteBBox, 4)
        AND
        measure_name = '$emissionsMetric'
      ORDER BY
        time
    `);

    await backendSrv.post(`/api/ruler/grafana/api/v1/rules/${folderUid}?subtype=cortex`, {
      name: 'alerts',
      interval: '5m',
      rules: [
        // all existing rules must also be passed here, otherwise they'll be removed.
        ...alertRules,
        // then we add new ones
        {
          grafana_alert: {
            title: alertParams.title,
            condition: 'Average or spike alert',
            no_data_state: 'NoData',
            exec_err_state: 'OK',
            data: [
              {
                refId: 'A',
                queryType: 'raw',
                relativeTimeRange: {
                  from: alertParams.lookbackSeconds,
                  to: 0,
                },
                datasourceUid: datasourceUid,
                model: {
                  datasource: {
                    type: 'grafana-timestream-datasource',
                    uid: datasourceUid,
                  },
                  intervalMs: 1000,
                  maxDataPoints: 43200,
                  measure: 'battery',
                  queryType: 'raw',
                  rawQuery: query,
                  refId: 'A',
                  table: '"sensors"',
                },
              },
              {
                refId: 'Average',
                queryType: '',
                relativeTimeRange: {
                  from: alertParams.lookbackSeconds,
                  to: 0,
                },
                datasourceUid: '__expr__',
                model: {
                  conditions: [
                    {
                      evaluator: {
                        params: [],
                        type: 'gt',
                      },
                      operator: {
                        type: 'and',
                      },
                      query: {
                        params: ['B'],
                      },
                      reducer: {
                        params: [],
                        type: 'last',
                      },
                      type: 'query',
                    },
                  ],
                  datasource: {
                    type: '__expr__',
                    uid: '__expr__',
                  },
                  expression: 'A',
                  intervalMs: 1000,
                  maxDataPoints: 43200,
                  reducer: 'mean',
                  refId: 'Average',
                  type: 'reduce',
                },
              },
              {
                refId: 'Average exceeded alert',
                queryType: '',
                relativeTimeRange: {
                  from: alertParams.lookbackSeconds,
                  to: 0,
                },
                datasourceUid: '__expr__',
                model: {
                  conditions: [
                    {
                      evaluator: {
                        params: [alertParams.averageAlertThreshold],
                        type: 'gt',
                      },
                      operator: {
                        type: 'and',
                      },
                      query: {
                        params: ['C'],
                      },
                      reducer: {
                        params: [],
                        type: 'last',
                      },
                      type: 'query',
                    },
                  ],
                  datasource: {
                    type: '__expr__',
                    uid: '__expr__',
                  },
                  expression: 'Average',
                  intervalMs: 1000,
                  maxDataPoints: 43200,
                  refId: 'Average exceeded alert',
                  type: 'threshold',
                },
              },
              {
                refId: 'Spike',
                queryType: '',
                relativeTimeRange: {
                  from: 0,
                  to: 0,
                },
                datasourceUid: '__expr__',
                model: {
                  conditions: [
                    {
                      evaluator: {
                        params: [0, 0],
                        type: 'gt',
                      },
                      operator: {
                        type: 'and',
                      },
                      query: {
                        params: [],
                      },
                      reducer: {
                        params: [],
                        type: 'avg',
                      },
                      type: 'query',
                    },
                  ],
                  datasource: {
                    name: 'Expression',
                    type: '__expr__',
                    uid: '__expr__',
                  },
                  expression: 'A',
                  intervalMs: 1000,
                  maxDataPoints: 43200,
                  reducer: 'max',
                  refId: 'Spike',
                  type: 'reduce',
                },
              },
              {
                refId: 'Spike exceeded alert',
                queryType: '',
                relativeTimeRange: {
                  from: 0,
                  to: 0,
                },
                datasourceUid: '__expr__',
                model: {
                  conditions: [
                    {
                      evaluator: {
                        params: [alertParams.spikeAlertThreshold, 0],
                        type: 'gt',
                      },
                      operator: {
                        type: 'and',
                      },
                      query: {
                        params: [],
                      },
                      reducer: {
                        params: [],
                        type: 'avg',
                      },
                      type: 'query',
                    },
                  ],
                  datasource: {
                    name: 'Expression',
                    type: '__expr__',
                    uid: '__expr__',
                  },
                  expression: 'Spike',
                  intervalMs: 1000,
                  maxDataPoints: 43200,
                  refId: 'Spike exceeded alert',
                  type: 'threshold',
                },
              },
              {
                refId: 'Average or spike alert',
                queryType: '',
                relativeTimeRange: {
                  from: 0,
                  to: 0,
                },
                datasourceUid: '__expr__',
                model: {
                  conditions: [
                    {
                      evaluator: {
                        params: [0, 0],
                        type: 'gt',
                      },
                      operator: {
                        type: 'and',
                      },
                      query: {
                        params: [],
                      },
                      reducer: {
                        params: [],
                        type: 'avg',
                      },
                      type: 'query',
                    },
                  ],
                  datasource: {
                    name: 'Expression',
                    type: '__expr__',
                    uid: '__expr__',
                  },
                  expression: '${Average exceeded alert} + ${Spike exceeded alert} > 0',
                  intervalMs: 1000,
                  maxDataPoints: 43200,
                  refId: 'Average or spike alert',
                  type: 'math',
                },
              },
            ],
            is_paused: false,
            notification_settings: {
              receiver: alertParams.receiver.value
            },
          },
          for: alertParams.pendingPeriod,
          annotations: {
            __dashboardUid__: dashboardUid,
            __panelId__: '25',
          },
          labels: {},
        },
      ],
    });

    // let users know it'll take a while until rules evaluate and show up on the alertlist panel
    appEvents.publish({
      type: AppEvents.alertSuccess.name,
      payload: ['Alert created!', 'It may take up to 5 minutes to appear on the dashboard.'],
    });

    // close modal and reset to default params
    setModalOpen(false);
    setAlertParams(alertDefaultParams);
  };

  return (
    <div style={{ display: 'flex' }}>
      <Modal
        title={replaceVariables("New ${emissionsMetric:text} alert").replace('Methane', 'methane')}
        isOpen={modalOpen}
        onDismiss={() => {
          setModalOpen(false);
        }}
        className={s.modalSmall}
      >
        <p>This alert will apply to all sensors on this site, regardless of filters you have applied.</p>
        <InlineField label="Title" labelWidth={24}>
          <Input
            aria-label="Title"
            className="width-24"
            value={alertParams.title}
            onChange={(evt: ChangeEvent<HTMLInputElement>) =>
              setAlertParams({ ...alertParams, title: evt.target.value })
            }
          />
        </InlineField>
        <InlineField
          label="Time frame"
          labelWidth={24}
          tooltip="Over the course of the past X minutes, an alert will be fired if either of the below thresholds are exceeded."
        >
          <Select
            aria-label="Time frame"
            className="width-24"
            value={alertParams.lookbackSeconds}
            onChange={(evt) => setAlertParams({ ...alertParams, lookbackSeconds: evt.value || 900 })}
            options={[
              { value: 900, label: '15 minutes' },
              { value: 1800, label: '30 minutes' },
              { value: 2700, label: '45 minutes' },
              { value: 3600, label: '60 minutes' },
            ]}
          />
        </InlineField>
        <InlineField
          label="Spike alert threshold"
          labelWidth={24}
          tooltip="Maximum value allowed over the course of the selected time frame."
        >
          <Input
            aria-label="Spike alert threshold"
            className="width-24"
            value={alertParams.spikeAlertThreshold}
            type="number"
            onChange={(evt) =>
              setAlertParams({ ...alertParams, spikeAlertThreshold: Number((evt.target as HTMLInputElement).value) })
            }
          />
        </InlineField>
        <InlineField
          label="Average alert threshold"
          labelWidth={24}
          tooltip="Maximum average of all values allowed over the course of the selected time frame."
        >
          <Input
            aria-label="Average alert threshold"
            className="width-24"
            value={alertParams.averageAlertThreshold}
            type="number"
            onChange={(evt) =>
              setAlertParams({ ...alertParams, averageAlertThreshold: Number((evt.target as HTMLInputElement).value) })
            }
          />
        </InlineField>
        <InlineField
          label="Pending period"
          labelWidth={24}
          tooltip="How long either threshold can be exceeded before the alert fires."
        >
          <Select
            aria-label="Pending period"
            className="width-24"
            value={alertParams.pendingPeriod}
            onChange={(evt) => setAlertParams({ ...alertParams, pendingPeriod: evt.value || '15m' })}
            options={[
              { value: '5m', label: '5 minutes' },
              { value: '10m', label: '10 minutes' },
              { value: '15m', label: '15 minutes' },
            ]}
          />
        </InlineField>
        <InlineField
          label="Contact point"
          labelWidth={24}
          tooltip="Who receives the alert."
        >
          <Stack gap={1} alignItems="center">
            <div style={{width: '356px'}}>
              <AsyncSelect
                aria-label="Contact point"
                loadOptions={fetchContactPoints}
                defaultOptions
                value={alertParams.receiver}
                onChange={(evt) => setAlertParams({ ...alertParams, receiver: evt })}
              />
            </div>
            <IconButton
              name="plus"
              tooltip="Add new contact point"
              variant='primary'
              onClick={() => location.href = `${location.origin}/alerting/notifications/receivers/new`}
            />
          </Stack>
        </InlineField>
        <Modal.ButtonRow>
          <Button
            variant="secondary"
            fill="outline"
            onClick={() => {
              setModalOpen(false);
              setAlertParams(alertDefaultParams);
            }}
          >
            Cancel
          </Button>
          <Button onClick={createAlert}>Save</Button>
        </Modal.ButtonRow>
      </Modal>
      <Button
        onClick={() => {
          setModalOpen(true);
        }}
        fullWidth
      >
        Add new alert
      </Button>
    </div>
  );
}

const getStyles = (theme: GrafanaTheme2) => ({
  modalSmall: css`
    width: fit-content;
  `,
});
