import { DataFrameJSON, SelectableValue } from "@grafana/data";
import { getBackendSrv } from "@grafana/runtime";

export function capitalizeFirstLetter(string: String) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function generateOptions(optionsArray: Array<String | SelectableValue>) {
  // takes array of lowercase keys and formats them to be select options
  // pass an array of options and they'll be formatted for grafana-ui's select component. option examples:
  //  <string>: returns {value: <string>, label: <String (first letter capitalized)>}
  //  {value, label}: returns the input object unchanged
  //  {value, description}: returns an object with value and description unchanged, but adds a label
  return optionsArray.map(option => {
    const optionObj: SelectableValue = typeof option === 'string' ? {value: option} : option // convert string options into an obj
    return {
      ...optionObj, // value, description, and imgUrl will now be sent through unchanged.
      label: optionObj?.label || capitalizeFirstLetter(optionObj.value) // compute the label if one wasn't passes
    }
  })
}

/**
 * Download contents as a file
 * Source: https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
 */
export function downloadBlob(content: string, filename: string, contentType: string) {
  // Create a blob
  const blob = new Blob([content], { type: contentType });
  const url = URL.createObjectURL(blob);

  // Create a link to download it
  const pom = document.createElement('a');
  pom.href = url;
  pom.setAttribute('download', filename);
  pom.click();
}

export async function fetchAllDataFrames(payload: any): Promise<DataFrameJSON> {
  const backendSrv = getBackendSrv();
  let combinedValues: any[] = [];
  let schema: any = null;
  let response = await backendSrv.post('/api/ds/query', payload);

  do {
    const frames = response.results.A.frames;

    // Ensure we have at least one frame and capture the schema from the first frame
    if (frames.length > 0) {
      if (!schema) {
        schema = frames[0].schema; // Capture the schema once
      }

      // Merge values from each frame into combinedValues
      frames.forEach((frame: any) => {
        frame.data.values.forEach((valueArray: any, index: number) => {
          if (!combinedValues[index]) {
            combinedValues[index] = [];
          }
          combinedValues[index] = combinedValues[index].concat(valueArray);
        });
      });
    }

    // Check for nextToken and update payload. if the response has a next token, try again.
    // without setting this token, your queries will lie to your fucking face and return 200 even though the results are empty.
    const nextToken = frames[0].schema.meta.custom.nextToken;
    if (nextToken) {
      payload.queries[0].nextToken = nextToken;
      response = await backendSrv.post('/api/ds/query', payload);
    } else {
      break;
    }
  } while (true);

  // Return a single large data frame
  return {
    schema: schema,
    data: { values: combinedValues },
  };
}
